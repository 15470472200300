<template>
  <div class="authoring-wrapper">
    <div class="flex h-full">
      <ProgressBar
        :stepIndex="3"
        :surveyID="surveyID"
        stepsType="map-maker"
        :conciergeAssistance="false"
        :progressBarStatus="progressBarStatus"
      />
      <section class="wrapper">
        <a-breadcrumb class="breadcrum breadcrum--authoring">
          <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
            <a-breadcrumb-item
              ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
            >
            <a-breadcrumb-item
              ><a href="/products">Products</a></a-breadcrumb-item
            >
            <a-breadcrumb-item>Stimuli</a-breadcrumb-item>
          </template>
        </a-breadcrumb>
        <div class="wrapper__inner">
          <div class="page-title">
            <icon-base
              :width="32"
              :height="33"
              :viewBox1="32"
              :viewBox2="33"
              iconName="chart"
              iconColor="transparent"
              ><icon-chart-histogram
            /></icon-base>
            <div class="page-title__text">
              <h1>Stimuli</h1>
              <span>Select Brands</span>
            </div>
          </div>
          <div class="box-wrapper" v-if="currentStep === 1">
            <StimuliTitle
              title="Select the brands that you want to run maps for. A cell will be created for each brand you select. Please ensure you upload the image that you want respondents to see for the exercise."
            ></StimuliTitle>

            <Loader
              class="mt-35"
              text="Loading brands..."
              v-if="loadingBrands"
            ></Loader>
            <div v-else class="management-system__grid">
              <BrandItem
                v-for="(item, index) in brands"
                :key="index"
                :brand="item"
                :loading="loadingBrands"
                @item-click="selectBrand(index)"
              ></BrandItem>
            </div>
          </div>
          <div class="box-wrapper" v-else>
            <StimuliTitle
              title="Please upload the imagery you want to show for each cell/brand you've selected."
            ></StimuliTitle>
            <div>
              <ChosenBrandItem
                v-for="(item, index) in brands"
                :key="index"
                :brand="item"
                :surveyID="surveyID"
                @set-brand-image="setBrandImage($event, index)"
                v-show="item.IsSelected"
              ></ChosenBrandItem>
            </div>
          </div>

          <StepNaviBar
            :nextDisable="nextDisable"
            :conciergeAssistance="true"
            @prevHandler="prevStep"
            @nextHandler="nextStep"
            stepsType="map-maker-test"
            @editable-survey-updated="handleEditableSurveyUpdate"
            @loading-survey-data="loadingBrands = $event"
            :loadingNextBtn="saveBrandsLoading"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
import IconBase from "@/components/general/IconBase.vue";
import IconChartHistogram from "@/components/icons/IconChartHistogram.vue";
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import BrandItem from "@/components/mapMakerTest/BrandItem.vue";
import ChosenBrandItem from "@/components/mapMakerTest/ChosenBrandItem.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar1.vue";
import Loader from "@/components/general/Loader.vue";

export default {
  name: "StimuliMapMaker",
  components: {
    StimuliTitle,
    IconBase,
    IconChartHistogram,
    ProgressBar,
    BrandItem,
    StepNaviBar,
    Loader,
    ChosenBrandItem,
  },
  props: {},
  data() {
    return {
      client: {},
      user: null,
      brands: [],
      surveyID: null,
      loadingBrands: true,
      currentStep: 1,
      saveBrandsLoading: false,
    };
  },
  computed: {
    disableNextStep() {
      return true;
    },
    nextDisable() {
      if (this.currentStep === 1) {
        return this.brands.filter((item) => item.IsSelected).length === 0;
      } else if (this.currentStep === 2) {
        return (
          this.brands.filter((item) => item.IsSelected).length === 0 ||
          this.brands.some((item) => item.IsSelected && !item.logoURL)
        );
      }
      return true;
    },
    selectedBrands() {
      return this.brands.filter((item) => item.IsSelected);
    },
    progressBarStatus() {
      let selectedBrand =
        this.brands.filter((item) => item.IsSelected).length !== 0;
      let imageUploaded = this.brands.some(
        (item) => item.IsSelected && item.logoURL
      );
      return [selectedBrand, imageUploaded];
    },
  },
  watch: {},
  methods: {
    handleEditableSurveyUpdate(surveyData) {
      if (surveyData && surveyData.SurveyConfig && surveyData.brands) {
        this.brands = surveyData.brands;
      } else {
        this.brands = [];
      }
    },
    selectBrand(index) {
      this.brands[index].IsSelected = !this.brands[index].IsSelected;
      this.saveBrands();
    },
    setBrandImage(imageURL, index) {
      this.brands[index].logoURL = imageURL;
      this.saveBrands();
    },
    saveBrands(nextStep) {
      if (nextStep) {
        this.saveBrandsLoading = true;
      }

      const data = {
        wsName: "SaveSurveyBrands",
        data: {
          surveyID: this.surveyID,
          brandData: this.brands,
          userId: this.user.EncrypteduserID,
        },
      };

      const saveBrands = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      saveBrands.then((data) => {
        if (data.Success && nextStep) {
          this.$router.push({
            name: "map-maker-test-launch",
            params: { id: this.surveyID },
          });
        } else if (data.Success) {
          let currentSummaryData = jsUtils.getSurveySummaryData();
          jsUtils.setSurveySummaryData({
            ...currentSummaryData,
            mapMakerBrandData: this.brands,
          });
        }
        this.saveBrandsLoading = false;
      });
    },
    prevStep() {
      if (this.currentStep === 2) {
        this.currentStep = 1;
      } else {
        this.$router.push({
          name: "map-maker-category-inputs",
          params: { id: this.surveyID },
        });
      }
    },
    nextStep() {
      if (this.currentStep === 1) {
        this.currentStep = 2;
      } else {
        this.saveBrands("nextStep");
      }
    },
  },
  mounted() {},
  created() {},
  activated() {
    this.surveyID = this.$route.params.id;
    this.client = jsUtils.getCurClient();
    this.user = jsUtils.getUserInfo();
  },
};
</script>

<style lang="scss" scoped>
.box-wrapper {
  min-height: 200px;
}
</style>
